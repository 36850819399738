<template>
  <div>
    <base-section id="endusermyagenda-account">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="6">
            <base-info-card title="My Agenda details" />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component({ name: "EndUserMyAgendaAccount" })
class EndUserMyAgendaAccount extends Vue {
  buildBaseRequestfilterBasic(value, andObject) {
    if (andObject !== undefined) {
      return { id: { ne: value }, and: andObject };
    } else {
      return { id: { ne: value } };
    }
  }

  buildBaseCategoryfilterBasic(value, andObject) {
    if (andObject !== undefined) {
      return { id: { ne: value }, and: andObject };
    } else {
      return { id: { ne: value } };
    }
  }

  created() {
    console.log("Created Agenda");
    // createAllCategories()
    // createCountryData()
    // listRequests(filter: {isProposalSP: {eq: true}, and: {isFixedPrice: {eq: false}, and: {id: {ne: "31afd6e6-9063-45fe-85d3-72e23650213c"}, and: {and: {id: {ne: "53946783-0a68-4f39-9814-09f47387608b"}, and: {id: {ne: "bcf64c7f-ad0e-4cec-ac21-e218bc5e7779"},and: {id: {ne: "f7c91cb0-4bc1-4800-accd-609727f04209"}}}}}}}})
    /*
      const requestIdList = ['f7c91cb0-4bc1-4800-accd-609727f04209', 'bcf64c7f-ad0e-4cec-ac21-e218bc5e7779', '53946783-0a68-4f39-9814-09f47387608b', '31afd6e6-9063-45fe-85d3-72e23650213c']
      var myFilter = {}
      var chainedRequestFilter = {}
      if (requestIdList.length === 0) {
        myFilter = { isProposalSP: { eq: true }, and: { isFixedPrice: { eq: false } } }
      } else {
        for (var i = 0; i < requestIdList.length; i++) {
          if (i === 0) {
            chainedRequestFilter = this.buildBaseRequestfilterBasic(requestIdList[i])
          } else {
            chainedRequestFilter = this.buildBaseRequestfilterBasic(requestIdList[i], chainedRequestFilter)
          }
          console.log('chainedRequestFilter' + i + ':')
          console.log(chainedRequestFilter)
        }
        myFilter = { isProposalSP: { eq: true }, and: { isFixedPrice: { eq: false }, and: chainedRequestFilter } }
      }
      console.log('myFilter:')
      console.log(myFilter)
      */
  }
}

export default EndUserMyAgendaAccount;
</script>
